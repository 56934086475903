<template>
  <div class="videopage">
    <div class="topbox">
      <div class="topbox-left">
        <span @click="back" class="totalback">
          <svg-icon icon-class="backIcon" class-name="backIcon"></svg-icon>
        </span>
        <p>
          <span class="tranincoursename" :title="courseName">{{
            courseName
          }}</span>
          <button class="knowle" @click="showMark()" v-if="LightPoints && LightPoints.length && ai_switch">
            知识点
          </button>
        </p>
      </div>
      <button class="next" @click="getNextCourseInfo()">下一项</button>
    </div>
    <div class="total videoBox">
      <!-- 视频：1 -->
      <el-drawer class="mark-drawer" :modal="false" :withHeader="false" :visible.sync="markDrawer" direction="ltr"
        v-wd-drag v-if="markDrawer && resourcesInfo && resourcesInfo.contentType == 1">
        <ul>
          <li v-for="(mark, index) in LightPoints" :key="index" @click="toMark(mark)">
            <span class="time">{{ mark.start }}</span>
            <span class="content">{{ mark.topic }}</span>
          </li>
        </ul>
      </el-drawer>
      <div class="video" id="videobox">
        <div class="chooseBox" :class="isCollapse ? 'chooseBox1' : 'chooseBox2'" @click="choose">
          <svg-icon :icon-class="isCollapse ? 'zhankai' : 'shouqi'" class-name="icon-img"></svg-icon>
        </div>
        <div class="container" id="player-con" style="height: calc(100vh - 90px)" v-if="
          this.resourcesInfo?.contentType == 1 ||
          this.resourcesInfo?.contentType == 2
        ">
          <!-- 音频波形图 -->
          <img v-if="this.resourcesInfo?.contentType == 2" class="music"
            src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/music.png" />
        </div>
        <doc-preview :detailId="resourcesInfo.contentId" v-if="this.resourcesInfo?.contentType == 3"
          :key="this.resourcesInfo.contentId" :duration="+duration"></doc-preview>
        <img v-if="this.resourcesInfo?.contentType == 4" :src="videoInfo.url" class="img" />
      </div>
      <div class="menu" :class="{ Collapse: !isCollapse }" :style="{ width: isCollapse == true ? '440px' : '0px' }">
        <el-tree :data="CourseTreelist" :props="defaultProps" accordion highlight-current
          :default-expanded-keys="expandedKeys" node-key="id" ref="tree" class="wdc-select-tree"
          @node-click="handleNodeClick1" @node-expand="nodeExpandClick"
          :style="{ marginLeft: isCollapse == true ? '0px' : '-500px' }">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span :id="data.id">
              <span :title="node.label" class="dy-label" :style="{
                marginLeft:
                  data.parentId == 0 &&
                    data.children &&
                    data.children.length > 0
                    ? '0px'
                    : '14px',
              }">
                <div :class="{ dyActive: resourcesInfo?.id == data.id }" class="index">
                  {{
                    CourseTreelist.findIndex((d) => {
                      return data.id === d.id;
                    }) + 1
                  }}.&nbsp;&nbsp;
                </div>
                <img :src="resourcesInfo?.id == data.id
                  ? `https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/${ConstData.courseType[data.contentType].icon
                  }.png`
                  : `https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/${ConstData.courseType[data.contentType].icon
                  }1.png`
                  " />
                <div :class="{ dyActive: resourcesInfo?.id == data.id }" class="title">
                  {{ node.label }}
                </div>
              </span>
              <span class="currentPlayText" v-if="resourcesInfo?.id == data.id">
                <i class="el-icon-s-data"></i>
                正在播放
              </span>

              <span class="lock-icon" v-if="data.isLocked">
                <svg-icon icon-class="lock"></svg-icon>
              </span>
            </span>
          </span>
        </el-tree>
      </div>
      <!-- <div class="bigline"></div> -->
      <el-dialog class="videoDialog" :visible.sync="dialogTableVisible" :close-on-click-modal="false"
        @close="closedialog">
        <svg-icon icon-class="tips" class-name="icon-img"></svg-icon>
        <div class="tip">提示</div>
        <div class="text">学习时禁止拖放进度！</div>
        <button class="btn" @click="btnclosedialog(1)">知道了</button>
      </el-dialog>
      <el-dialog class="alertDialog" :visible.sync="continueDialogVisible" :close-on-click-modal="false"
        @close="closedialog">
        <svg-icon icon-class="tips" class-name="icon-img tips-icon"></svg-icon>
        <div class="text">
          {{
            this.TrainBasicInfo?.alertWindowTip ||
            "亲爱的学员，还在吗？请不要走开，点击 ”我在“继续学习，否则您离开的时间将不计入学时"
          }}
        </div>
        <button class="btn" @click="btnclosedialog(2)">我在，继续学习</button>
      </el-dialog>
    </div>
    <facedialog v-if="verification" :confirmdialog="confirmdialog" :address="verificationAddress"
      :failAddress="verificationFailAddress" :type="1" :pauseFrame="true"
      @CloseSuccessFacedialog="CloseSuccessFacedialog" @failCloseFacedialog="failCloseFacedialog"
      :businessId="businessId" :taskId="taskId"></facedialog>
    <practiceDialog :questionDataDialog="questionDataDialog" v-if="practiceDialogVisible" :dialogVisible="practiceDialogVisible"
      :resourceId="resourcesInfo.contentId" @handleClose="handleClose" @submitExam="submitExam" :taskId="taskId">
    </practiceDialog>

  </div>
</template>

<script>
import $ from "jquery";
import docPreview from "./docPreview.vue";
import TCPlayer from "tcplayer";
import wdDrag from "@/directive/drag"; //拖拽
import practiceDialog from "./practiceDialog.vue";
let enterFullPath = {
  path: "",
  query: "",
};
export default {
  name: "courseVideo",
  components: { docPreview, practiceDialog },
  data() {
    return {
      practiceDialogVisible: false,
      SubtitlesAddress: [], //字幕地址
      LightPoints: [], //亮点标记
      markDrawer: true,
      taskId: "", // 任务id
      confirmdialog: {
        show: true,
      }, // 人脸识别弹窗
      verification: false,
      businessId: "",
      duration: "", // 文档总进度
      CourseTreelist: [], // 扁平化数据 获取所有课程
      taskTreeList: [],
      expandedKeys: [],
      resourcesInfo: null, //当前播放的资源
      scrom: {
        resId: 0,
        version: "1.2",
      },
      defaultProps: {
        children: "children",
        label: "contentName",
      },
      isCollapse: true,
      canPlay: false,
      catelogList: [],
      TrainBasicInfo: {
        isAlertWindow: 0,
      },
      is: true,
      videoInfo: {
        video: null,
        url: [],
        source: {},
        deadline: 0,
        index: 0,
      },
      IntervalName: null,
      player: null,
      tcplayer: null,
      name: "",
      pdfIntervalName: null,
      dialogTableVisible: false,
      continueDialogVisible: false,
      allPlayEndIdList: [],
      alertTime: null,
      verificationAddress: {},
      verificationFailAddress: {},
      app: null,
      indexId: 0,
      resId: null, //当前资源id
      courseName: "", //当前播放的课程名称
      showAlertArr: [], // 获取视频播放中需要弹窗的时间点数据
      timeTStr: 0, //视频当前播放时间点 用来弹出弹屏设置
      faceRecognizeTimer: null, // 人脸识别定时器
      faceRecognizeFail: false, // 人脸识别失败
      webpageFlag: true,
      saveLogTime: 15,
      pauseTime: 0,
      ai_switch: false,
      options: {
        // fileID: '243791581722038521', // 请传入需要播放的视频 fileID（必须）
        // appID: '1500020443', // 请传入点播账号的 appID（必须）
        // psign: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6MTUwMDAyMDQ0MywiZmlsZUlkIjoiMjQzNzkxNTgxNzM1MDkxNDk2IiwiY3VycmVudFRpbWVTdGFtcCI6MTY4MjU2NTYwOSwiY29udGVudEluZm8iOnsiYXVkaW9WaWRlb1R5cGUiOiJPcmlnaW5hbCJ9LCJleHBpcmVUaW1lU3RhbXAiOjE2ODI2MTEyMDAsInVybEFjY2Vzc0luZm8iOnsiZG9tYWluIjoiMTUwMDAyMDQ0My52b2QyLm15cWNsb3VkLmNvbSIsInNjaGVtZSI6IkhUVFBTIn19.3zkXP4Cs-NxK9OKZ_4v1RsztZtCWDNbzXmChJmfEId8',
        autoplay: true,
        controlBar: {
          progressControl: true,
        },
      },
      videoPlayChannel: "tencent",
      lockCourseRate: 0,
      isOnce: false,
      isPractice: 0,//是否练习
      questionDataDialog:[]
    };
  },
  directives: {
    wdDrag,
  },
  created() {
    this.initConfig();
    if (this.ai_switch) {
      this.$set(this.options, "plugins", { ProgressMarker: true });
    }
  },
  async mounted() {
    this.courseName = this.$route.query.courseName;
    this.taskId = this.$route.query.taskId;
    this.resId = this.$route.query.resId;
    await this.findfaceNeed(); // 培训规则
    await this.taskDetailWithoutLogin();

    //监听当前页改变事件。
    if (this.app) {
      this.app.Sub.CurrentPageChange = this.eventHandle;
    }
  },
  beforeRouteEnter(to, from, next) {
    // ...
    enterFullPath.path = from.path;
    enterFullPath.query = from.query;
    next();
  },
  methods: {
    submitExam() {
      this.practiceDialogVisible = false;
      //开始下一个视频学习
      this.getNextCourseInfo();
    },
    handleClose(done) {
      this.practiceDialogVisible = false;
      //开始下一个视频学习
      this.getNextCourseInfo();
      done();
    },
    // 初始化详情
    async taskDetailWithoutLogin() {
      this.CourseTreelist = [];
      const params = {
        id: this.taskId || null,
      };
      const res = await this.$api.courseCenter.taskDetailWithoutLogin({
        params,
      });
      if (res.data) {
        this.taskTreeList = res.data.taskItemVoList || [];
        if (res.data.type == 4) {
          this.taskTreeList = res.data.taskItemVoList[0].children || []
        }
        await this.taskDetailExt();
        this.getAllresFile(this.taskTreeList); // 获取课程扁平化数据
        console.log(this.CourseTreelist, "CourseTreelist==");
        console.log(this.resId, "==========resId");
        const temp = this.CourseTreelist.filter((item) => {
          return item.contentId === +this.resId;
        });
        console.log("temp=============================", temp);
        this.resourcesInfo = temp[this.indexId];
        /** 第一次学习 studyInfo 为null */
        this.resourcesInfo.studyInfo = this.resourcesInfo.studyInfo || {};
        this.expandedKeys = [this.resourcesInfo.id];
        this.courseName = this.resourcesInfo.contentName;
        // //当前播放的视频一定要有进度
        // this.resourcesInfo.studyInfo = {
        //   ...this.resourcesInfo.studyInfo,
        //   studyProcess: 1
        // }
        this.resourcesPlay(this.resourcesInfo);

      }
    },

    /** 遍历大纲 返回学习相关信息 */
    changeTaskTree(data, data2) {
      const idToItem2Map = {};
      // 构建 data2 的哈希表，以便通过 id 查找项
      loop(data2);
      function loop(node) {
        node.forEach((item2) => {
          idToItem2Map[item2.id] = item2;
          if (item2.children && item2.children.length) {
            loop(item2.children);
          }
        });
      }

      console.log("idToItem2Map", idToItem2Map);
      /* eslint-disable */
      // 递归地更新 data2 中的项
      function updateData2(data) {
        data.forEach((item) => {
          if (idToItem2Map.hasOwnProperty(item.id)) {
            const item2 = idToItem2Map[item.id];
            item2.studyProcess = item.studyProcess;
            item2.studyInfo = item.studyInfo;
            item2.isLocked = item.isLocked;
            item2.courseId = item.courseId;
          }
          if (item.children && item.children.length) {
            updateData2(item.children);
          }
        });
      }
      // 开始递归更新
      updateData2(data);
      console.log("data2", data2);
      this.taskTreeList = data2;
    },
    // 获取资源信息
    getGptResourceInfo(id) {
      this.$api.gpt
        .getGptResourceInfo({
          resource_id: id,
        })
        .then((res) => {
          if (res.code == 0) {
            this.LightPoints = res.data.light_points;
            // this.SubtitlesAddress = res.data.SubtitlesAddress;
          }
        });
    },
    getVideoSubtitle(id) {
      this.$api.gpt
        .getVideoSubtitle({
          resource_id: id,
          // Summarize: 0,
          // SubtitlesAddress: 1,
        })
        .then((res) => {
          if (res.code == 0) {
            this.SubtitlesAddress = res.data.vtt_list;
          }
        });
    },
    // 二次渲染详情 返回学习信息
    async taskDetailExt() {
      const params = {
        id: this.taskId,
      };
      const res = await this.$api.learn.taskDetailExt({ params });
      if (res.data) {
        this.isPractice = res.data.isPractice;
        let tree = [];
        for (let key in res.data.itemMap) {
          tree.push(res.data.itemMap[key]);
        }
        this.changeTaskTree(tree, this.taskTreeList);
      }
    },
    //获取全部素材
    async getAllresFile(courseData) {
      if (courseData && courseData.length) {
        // 2023-12-12：项目、新员工任务-素材只显示当前打开的，课程显示当前课程目录-下期做
        // if(this.$route.query.source == 'courseList' || this.$route.query.source == 'studyTask'){
        //   courseData.forEach((item) => {
        //     if (item.contentType&&item.parentId == this.$route.query.parentId) {
        //       this.CourseTreelist.push(item);
        //     } else if (item.children && item.children.length > 0) {
        //       return this.getAllresFile(item.children);
        //     }
        //   });
        // }else{
        courseData.forEach((item) => {
          if (item.contentType) {
            this.CourseTreelist.push(item);
          } else if (item.children && item.children.length > 0) {
            return this.getAllresFile(item.children);
          }
        });
        // }
      }
    },
    initConfig() {
      let configArr =
        localStorage.getItem("configurationArr") &&
        JSON.parse(localStorage.getItem("configurationArr"));
      configArr.forEach((item) => {
        switch (item.key) {
          case "course_saveLog_time":
            this.saveLogTime = item.value || 15;
            break;
          case "ai_switch":
            this.ai_switch = (item.value == 'true' || item.value == true) ? true : false;
            break;
          default:
            break;
        }
      });
      console.log(this.saveLogTime, "this.saveLogTime");

    },
    /**
     * 获取课程里下一个资源
     */
    async getNextCourseInfo() {
      await this.taskDetailExt();
      this.indexId = this.indexId + 1;
      if (this.indexId < this.CourseTreelist.length) {
        if (this.CourseTreelist[this.indexId].isLocked) {
          this.$message({
            showClose: true,
            message: "下一个课程未达到解锁规则，请返回学习大纲，按规则进行学习",
            type: "warning",
          });
          this.indexId = this.indexId - 1;
        } else {
          this.resourcesInfo = this.CourseTreelist[this.indexId];
          /** 第一次学习 studyInfo 为null */
          this.resourcesInfo.studyInfo = this.resourcesInfo.studyInfo || {};
          this.expandedKeys = [this.resourcesInfo.id];
          this.resourcesPlay(this.resourcesInfo);
        }
      } else {
        this.$message({
          showClose: true,
          message: "已经是最后一个内容了",
          type: "warning",
        });
      }
    },
    /**
     * 人脸识别成功确定事件
     */
    CloseSuccessFacedialog() {
      this.verification = false;
      this.faceRecognizeFail = false;
      if (!this.continueDialogVisible) {
        this.player && this.player.play();
        this.tcplayer && this.tcplayer.play();
      }
    },
    /**
     * 人脸识别失败事件 或者过程中关闭了人脸
     */
    failCloseFacedialog() {
      console.log("关闭或失败切换视频");
      this.faceRecognizeFail = true;
    },
    async back() {
      /**
       * 返回之前保存进度
       */
      if (this.videoInfo.video) {
        await this.postCourseLearningLogSaveLog();
      }
      this.$route.params.ishint = 0;
      // this.$router.push({
      //   path:'/learn/course/detail',
      //   query:{
      //     id:this.$route.query.courseId
      //   }
      // })
      this.$router.back();
    },

    async handleNodeClick1(data, node) {
      console.log(data, node, "=====1212121212121212");
      this.isOnce = true;

      /* eslint-disable */
      console.log(this.TrainBasicInfo.lockCourseRate, "this.TrainBasicInfo");
      if (this.videoInfo.video) {
        await this.postCourseLearningLogSaveLog(); // 切换之前保存进度
      }

      await this.taskDetailExt(); //获取最新目录
      let tar = this.CourseTreelist.find((task) => {
        return task.id === data.id;
      });
      console.log("tar", tar);

      if (tar?.isLocked) {
        this.$message.warning(`完成上一个学习内容才能开启当前学习内容哦～`);
        return false;
      }
      this.courseName = data.contentName;
      this.resourcesInfo = data;
      /** 第一次学习 studyInfo 为null */
      this.resourcesInfo.studyInfo = this.resourcesInfo.studyInfo || {};
      this.resId = data.contentId;
      this.resourcesPlay(data);
      console.log(this.CourseTreelist, "=========CourseTreelist==");
    },
    nodeExpandClick(data, node) {
      this.updateChildren(node);
    },
    updateChildren(list) {
      if (list.childNodes.length > 0) {
        list.childNodes.forEach((item) => {
          item.expanded = true;
          this.updateChildren(item);
        });
      }
    },
    // 资源播放
    async resourcesPlay(data) {
      console.log("=====data=========", data);
      this.$store.state.coures.currentlyPlay = data;
      this.lockCourseRate = data.lockCourseRate;
      this.courseName = data.contentName;
      /**
       * 解决下标问题
       */
      /* eslint-disable */
      const is = this.CourseTreelist.some((item1, index) => {
        if (data.id === item1.id) {
          this.indexId = index;
          return false;
        }
      });

      if (this.TrainBasicInfo.isTrainTimePassed == 1) {
        this.$message({
          showClose: true,
          message: "尚未开始哦",
          type: "warning",
        });
        return;
      }
      this.markDrawer = false;
      // console.log(data,'data.type---')
      // 刚进入页面不需要弹人脸识别
      if (this.isOnce) {
        this.faceRecognizeFail = true;
      }

      console.log(this.faceRecognizeFail, "this.faceRecognizeFail");
      //0 目录；1，视频；2，音频；3，文档；4，图片；6，scorm；9，试卷；10，练习；11，直播；12，实锤
      if (data.contentType == 1 || data.contentType == 2) {
        if (data.contentType == 1 && this.ai_switch) {
          this.getGptResourceInfo(data.contentId);
          this.getVideoSubtitle(data.contentId);
        }
        // 视频
        this.getVideoPlayURLById(data);
        this.clearPdfTimeing();
        if (data.contentType == 2) {
          this.courseClearTimeing();
        }
      } else if (data.contentType == 3) {
        // pdf
        this.duration = data.duration;
        this.pdfTiming(); // 保存接口
        this.courseClearTimeing(); // 清除定时器
        this.removePlay();
      } else if (data.contentType == 4) {
        // 图片
        this.getImageOrDocUrl(data);
        this.removePlay();
        this.pdfTiming();
        this.courseClearTimeing();
      }
      // 自动滚动到选中节点的可视区域
      setTimeout(() => {
        const node = document.getElementById(data.id); // 通过Id获取到对应的dom元素
        if (node) {
          this.$nextTick(() => {
            node.scrollIntoView({ block: "center" }); // 通过scrollIntoView方法将对应的dom元素定位到可见区域 【block: 'center'】这个属性是在垂直方向居中显示
          });
        }
      }, 200);
    },
    /**
     * 弹屏 弹窗关闭触发事件
     */
    closedialog() {
      if (!this.verification) {
        try {
          this.player && this.player.play();
          this.tcplayer && this.tcplayer.play();
        } catch (e) {
          console.log("e", e);
        }
      }
    },
    /**
     * 弹屏 点击我知道了 触发事件
     */
    btnclosedialog(num) {
      if (num == 1) {
        // 禁止拖拽
        this.dialogTableVisible = false;
      } else if (num == 2) {
        // 弹屏
        this.continueDialogVisible = false;
      }
      // 如果正在人脸识别则不播放
      if (!this.verification) {
        this.player && this.player.play();
        this.tcplayer && this.tcplayer.play();
      }
    },
    /** 收缩 tree */
    choose() {
      this.isCollapse = !this.isCollapse;
    },
    /**
     * 获取设置规则
     */
    async findfaceNeed() {
      console.log(this.taskId);
      await this.$api.coursePlay.findTaskRule(this.taskId).then((res) => {
        this.TrainBasicInfo = res.data.supervisionRule;
        this.alertTime = this.TrainBasicInfo.alertTime * 60 * 1000;
      });
    },
    // 获取视频播放地址
    async getVideoPlayURLById(data) {
      const params = {
        resId: data.contentId,
      };
      this.is = true;
      if (this.videoPlayChannel === "tencent" && data.contentType == 1) {
        return this.$api.resource
          .getPlayAuthTokenInfo({ params })
          .then((res) => {
            this.videoInfo.source = res.data;
            try {
              this.player && this.player.dispose();
              this.tcplayer && this.tcplayer.dispose();
            } catch (e) {
              console.log("e", e);
            }
            if (res.data.sourceSing === "555") {
              this.gettcPlay(this.videoInfo);
            } else {
              const vi = Object.assign({}, this.videoInfo);
              vi.source.tencentPlayVideoResp = { playUrl: vi.source.playUrl };
              this.gettcPlay(vi);
            }
          });
      } else {
        return this.$api.resource.getPlayAuthToken({ params }).then((res) => {
          this.videoInfo.source = res.data;
          try {
            this.player && this.player.dispose();
            this.tcplayer && this.tcplayer.dispose();
          } catch (e) {
            console.log("e", e);
          }
          if (res.data.sourceSing === "555") {
            this.gettcPlay(this.videoInfo);
          } else {
            this.getaliPlay();
          }
        });
      }
    },
    // 获取图片文档
    async getImageOrDocUrl(data) {
      const params = {
        id: data.contentId,
      };
      return this.$api.resource.getImageOrDocUrl({ params }).then((res) => {
        if (res.data) {
          this.videoInfo.url = res.data;
        }
      });
    },
    async progressSetting(dataInfo) {
      if (dataInfo && dataInfo.studyInfo) {
        const deadline =
          (this.player.getDuration() || dataInfo.duration || 0) *
          ((dataInfo.studyInfo?.learnRate || 0) * 0.01);
        this.videoInfo.deadline =
          deadline >= (this.player.getDuration() || dataInfo.duration || 0)
            ? this.player.getDuration()
            : deadline;
        if (dataInfo.studyInfo.playLength) {
          this.videoInfo.video.currentTime = dataInfo.studyInfo.playLength;
        } else {
          this.videoInfo.video.currentTime =
            this.videoInfo.deadline >=
              (this.player.getDuration() || dataInfo.duration || 0)
              ? 0
              : this.videoInfo.deadline || 0;
        }
      }
    },
    async tcProgressSetting(dataInfo) {
      if (dataInfo && dataInfo.studyInfo) {
        const deadline =
          (this.tcplayer.duration() || dataInfo.duration || 0) *
          ((dataInfo.studyInfo.learnRate || 0) * 0.01);
        this.videoInfo.deadline =
          deadline >= (this.tcplayer.duration() || dataInfo.duration || 0)
            ? this.tcplayer.duration()
            : deadline;
        if (dataInfo.studyInfo.playLength) {
          this.videoInfo.video.currentTime = dataInfo.studyInfo.playLength;
        } else {
          this.videoInfo.video.currentTime =
            this.videoInfo.deadline >=
              (this.tcplayer.duration() || dataInfo.duration || 0)
              ? 0
              : this.videoInfo.deadline || 0;
        }
      }
    },
    removePlay() {
      var $ = require("jquery");
      if ($("#player-con").length > 0) {
        try {
          this.player && this.player.dispose();
          this.tcplayer && this.tcplayer.dispose();
        } catch (e) {
          console.log("e", e);
        }
        $("#player-con").empty();
      }
    },
    //观看课程信息保存
    async postCourseLearningLogSaveLog() {
      let cur = "",
        max = "";
      if (this.videoInfo.video) {
        cur =
          this.videoInfo.video.currentTime &&
            this.videoInfo.video.currentTime > 0
            ? this.videoInfo.video.currentTime
            : 1;
        max =
          this.videoInfo.video.duration && this.videoInfo.video.duration > 0
            ? this.videoInfo.video.duration
            : 1;
      }

      /**
       * saveLogtime ：默认15s 配置的秒数
       * 如果进度小于saveLogtime秒，直接保存总进度
       */

      console.log(+this.saveLogTime + 5, "this.saveLogtime+5");
      if (
        this.videoInfo.video?.duration - this.videoInfo.video?.currentTime <=
        +this.saveLogTime + 5
      ) {
        cur = this.videoInfo.video.duration;
      }
      console.log(cur, "cur===");

      const data = {
        taskId: this.taskId || "",
        resId: this.resourcesInfo.contentId, // 资源id
        courseId: this.resourcesInfo.courseId || "", //课程下资源需要传
        cur, // 当前时长
        max,
        pollingTime: this.saveLogTime || 15,
        platform: 0,
      };
      for (let key in data) {
        if (!data[key]) {
          console.log(key, "key---", data[key]);
        }
      }
      await this.$api.coursePlay
        .postCourseLearningLogSaveLog({ data })
        .then((res) => {
          console.log(res);
        });
    },
    // 清除课程定时器
    async courseClearTimeing() {
      if (this.courseIntervalName) {
        clearInterval(this.courseIntervalName);
        this.courseIntervalName = null;
      }
    },
    /**
     * 读取配置项保存时间
     * saveLogTime ： 默认15s，可配置
     * 课程定时器，saveLogTime 保存一次
     */
    async courseTiming() {
      if (this.courseIntervalName) {
        clearInterval(this.courseIntervalName);
      }
      const saveLogTime = +this.saveLogTime * 1000;
      this.courseIntervalName = setInterval(async () => {
        this.postCourseLearningLogSaveLog();
      }, saveLogTime);
      return this.courseIntervalName;
    },
    // 获取显示弹屏提示的时间点的数组
    getShowAlertArrNum(totalTime, nums) {
      let totalNum = totalTime / (nums + 1);
      let showAlertArr = [];
      for (let i = 0; i <= nums + 1; i++) {
        showAlertArr.push(Math.ceil(totalNum * i));
      }
      showAlertArr.shift();
      showAlertArr.pop();
      this.showAlertArr = showAlertArr;
    },
    /**
     * 课程设置按次数弹屏，获取按次数弹屏的时间段
     */
    setDialogNum() {
      // console.log(this.timeTStr,'this.timeTStr');
      console.log(
        Math.ceil(this.player.getCurrentTime()),
        "Math ceil(this.player.getCurrentTime())"
      );
      if (this.timeTStr !== Math.ceil(this.player.getCurrentTime())) {
        if (this.showAlertArr.length > 0) {
          if (
            this.showAlertArr.indexOf(
              Math.ceil(this.player.getCurrentTime())
            ) !== -1
          ) {
            this.player.pause();
            this.continueDialogVisible = true;
            this.timeTStr = Math.ceil(this.player.getCurrentTime());
          }
        }
      }
    },
    setTcDialogNum() {
      // console.log(this.timeTStr,'this.timeTStr');
      console.log(
        Math.ceil(this.tcplayer.currentTime()),
        "Math ceil(this.tcplayer.currentTime())"
      );
      if (this.timeTStr !== Math.ceil(this.tcplayer.currentTime())) {
        if (this.showAlertArr.length > 0) {
          if (
            this.showAlertArr.indexOf(
              Math.ceil(this.tcplayer.currentTime())
            ) !== -1
          ) {
            this.tcplayer.pause();
            this.continueDialogVisible = true;
            this.timeTStr = Math.ceil(this.tcplayer.currentTime());
          }
        }
      }
    },
    faceRecognizeDialog() {
      this.player.pause();
      this.verificationAddress = {};
      this.verificationFailAddress = {};
      this.verification = true;
      this.confirmdialog.show = true;
    },
    faceTcRecognizeDialog() {
      console.log(this.videoInfo.video, "this.videoInfo.video===");
      this.videoInfo.video.pause();
      // this.tcplayer.pause()
      this.verificationAddress = {};
      this.verificationFailAddress = {};
      this.verification = true;
      this.confirmdialog.show = true;
    },
    setFaceRecognizeDialog() {
      let time = this.TrainBasicInfo.courseFaceRecognizeTime * 60 * 1000;
      console.log(time, "time=====");
      this.faceRecognizeTimer = setInterval(() => {
        console.log("定时器人脸识别222");
        this.faceRecognizeDialog();
        clearInterval(this.faceRecognizeTimer);
      }, time);
    },
    setTcFaceRecognizeDialog() {
      let time = this.TrainBasicInfo.courseFaceRecognizeTime * 60 * 1000;
      console.log(time, "1122time=====");
      this.faceRecognizeTimer = setInterval(() => {
        console.log("定时器人脸识别232");
        this.faceTcRecognizeDialog();
        clearInterval(this.faceRecognizeTimer);
        this.faceRecognizeTimer = null;
        console.log(this.faceRecognizeTimer, "faceRecognizeTimer");
      }, time);
    },
    gettcPlay(videoInfo) {
      this.businessId = this.resourcesInfo.contentId;
      const timestamp = Date.now();
      var $ = require("jquery");
      $("#player-con").height("calc(100vh - 90px)");
      $("#player-con").children().remove();
      try {
        this.tcplayer && this.tcplayer.dispose();
      } catch (e) {
        console.log("e", e);
      }
      // const musicdiv = `<img class="music" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/music.png" />`
      const boarddiv = `<video id="player-container-id_${this.businessId}_${timestamp}" style="width:100%; height:100%;" preload="auto" playsinline webkit-playsinline></video>`;
      $("#player-con").append(boarddiv);
      // $('#player-con').append(musicdiv)
      // $('#player-container-id').width('100%').height('100%');
      const options = Object.assign({}, this.options);
      if (
        this.TrainBasicInfo.isDoubleSpeed &&
        (!this.resourcesInfo.studyInfo ||
          (this.resourcesInfo.studyInfo.learnRate || 0) < 100)
      ) {
        options.controlBar.playbackRateMenuButton = false;
      } else {
        options.controlBar.playbackRateMenuButton = true;
      }
      // console.log(this.resourcesInfo,'this.resourcesInfo===========================')
      if (
        +this.TrainBasicInfo.isDraw === 1 &&
        (!this.resourcesInfo.studyInfo ||
          (this.resourcesInfo.studyInfo.learnRate || 0) < 100)
      ) {
        options.controlBar.progressControl = false;
      } else {
        options.controlBar.progressControl = true;
      }
      if (videoInfo.source.tencentPlayVideoResp.psign) {
        options.fileID = videoInfo.source.tencentPlayVideoResp.fileId;
        options.appID = videoInfo.source.tencentPlayVideoResp.appId;
        options.psign = videoInfo.source.tencentPlayVideoResp.psign;
      }
      if (videoInfo.source.tencentPlayVideoResp.playUrl) {
        options.sources = [
          { src: videoInfo.source.tencentPlayVideoResp.playUrl },
        ];
      }
      this.tcplayer = TCPlayer(
        `player-container-id_${this.businessId}_${timestamp}`,
        options
      );
      this.tcplayer.on("ready", () => {
        // 字幕
        if (this.SubtitlesAddress && this.SubtitlesAddress.length > 0) {
          this.SubtitlesAddress.map((item, index) => {
            var subTrack = this.tcplayer.addRemoteTextTrack(
              {
                src: item.vtt_url,
                kind: "subtitles",
                srclang: item.language,
                label: item.name,
                default: false,
              },
              true
            );
          });
        }
        // console.log('videoready=========================')
        /**
         * isAlertWindow: 弹屏开启 1 开启 0 关闭
         * alertCount：弹屏次数
         * alertType：弹屏类型 1 按次数 0 按时间
         */
        if (
          this.TrainBasicInfo.isAlertWindow &&
          this.TrainBasicInfo.alertType
        ) {
          this.getShowAlertArrNum(
            this.resourcesInfo.duration,
            this.TrainBasicInfo.alertCount
          );
        }

        /**
         * 记录上次播放进度
         */
        console.log("hanmeng==== this.resourcesInfo", this.resourcesInfo);
        const duration = this.resourcesInfo.duration || 0;
        console.log("hanmeng==== duration", duration);
        if (
          (this.resourcesInfo.studyInfo.learnRate || 0) >= 100 &&
          (this.resourcesInfo.studyInfo.playLength || 0) + 5 >= duration
        ) {
          this.tcplayer.currentTime(0);
        } else {
          if (duration >= 0 && duration <= 30) {
            this.tcplayer.currentTime(0);
          } else if (this.resourcesInfo.studyInfo) {
            if (
              (this.resourcesInfo.studyInfo.playLength || 0) + 30 >
              duration
            ) {
              this.tcplayer.currentTime(duration - 30);
            } else {
              this.tcplayer.currentTime(
                this.resourcesInfo.studyInfo.playLength || 0
              );
            }
          } else {
            this.tcplayer.currentTime(0);
          }
        }
      });

      /* eslint-disable */
      this.tcplayer.on("play", async (e) => {
        console.log(
          this.videoInfo.video.currentTime,
          this.tcplayer.currentTime(),
          "开始播放当前时间this.videoInfo.video.currentTime====="
        );
        /** 清除人脸识别 */
        if (this.faceRecognizeTimer) {
          clearInterval(this.faceRecognizeTimer);
        }
        console.log(
          this.videoInfo.video.currentTime,
          this.tcplayer.currentTime(),
          "开始播放当前时间this.videoInfo.video.currentTime====="
        );
        if (this.timer) {
          clearInterval(this.timer);
        }
        /**
         * 弹屏按时间
         * isAlertWindow: 弹屏开启 1 开启 0 关闭
         * alertType：弹屏类型 1 按次数 0 按时间
         * alertTime：弹屏时间
         */
        if (
          this.TrainBasicInfo.isAlertWindow &&
          !this.TrainBasicInfo.alertType &&
          (!this.resourcesInfo.studyInfo ||
            (this.resourcesInfo.studyInfo.learnRate || 0) < 100)
        ) {
          this.timer = setInterval(() => {
            console.log("弹屏222");
            this.tcplayer.pause();
            this.continueDialogVisible = true;
            clearInterval(this.timer);
          }, this.alertTime);
        }
        if (
          !this.verification &&
          +this.TrainBasicInfo.isCourseFaceRecognize === 1 &&
          (!this.resourcesInfo.studyInfo ||
            (this.resourcesInfo.studyInfo.learnRate || 0) < 100)
        ) {
          if (this.TrainBasicInfo.courseFaceRecognizeTime) {
            // 设置了人脸识别间隔时间
            this.setTcFaceRecognizeDialog();
          }
        }

        this.videoInfo.fastForward = false;
        if (
          this.resourcesInfo.studyInfo &&
          this.resourcesInfo.studyInfo.learnRate < 100 &&
          +this.TrainBasicInfo.isDraw === 1
        ) {
          /**
           * 培训设置视频防拖拽 调用此方法
           */
          this.tcProgressSetting(this.resourcesInfo);
        }
        await this.courseClearTimeing();
        await this.courseTiming();
      });
      this.tcplayer.on("pause", async () => {
        console.log(
          this.videoInfo.video.currentTime,
          "暂停播放当前时间this.videoInfo.video.currentTime====="
        );
        this.pauseTime = this.videoInfo.video.currentTime;
        this.is = false;
        await this.courseClearTimeing();
      });
      /**
       * 视频播放完成
       */
      this.tcplayer.on("ended", async () => {
        /**
         * 视频播放完成 清楚弹窗定时器
         */
        this.continueDialogVisible = false; // 多加一层保障
        clearInterval(this.timer); // 清除弹屏定时器
        clearInterval(this.faceRecognizeTimer); // 清除人脸识别定时器
        await this.postCourseLearningLogSaveLog();
        this.allPlayEndIdList.push(this.resourcesInfo.contentId);
        this.is = true;
        this.courseClearTimeing();
        this.videoInfo.deadline = 0;
        // await this.taskDetailExt();
        console.log("================index", this.indexId);
        // 如果 isPractice是1的话，就不会自动播放下一个 弹出练习的弹窗
        if (this.isPractice == 1) {
          let resData = await this.$api.resource.getRandomQuestion({ resourceId: this.resourcesInfo.contentId })
          if (resData.success) {
            if (resData.data.length > 0) {
              this.questionDataDialog = resData.data;
              // 二次确认弹窗 课程学习完成，开始练习吧
              console.log("课程学习完成，开始练习吧");
              this.$confirm("课程学习完成，开始练习吧", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })

                .then(() => {
                  this.practiceDialogVisible = true;
                  return;
                })
            }else{
              this.getNextCourseInfo();
            }
          }else{
            this.$message({
              showClose: true,
              message: resData.message,
              type: "warning",
            });
          }
        } else {
          this.getNextCourseInfo();
        }
        /**
         * 完成 自动播放下一个
         */
        // this.getNextCourseInfo();
      });

      this.videoInfo.video = document.querySelector("video");
      if (!this.videoInfo.video) {
        this.videoInfo.video = document.querySelector("audio");
      }
      if (!this.videoInfo.video) {
        return;
      }
      /**
       * 视频进度发生变化
       */
      // this.videoInfo.video.addEventListener('timeupdate', () => {
      this.tcplayer.on("timeupdate", async () => {
        /**
         * 人脸验证，规则开启人脸识别并且设置 分钟
         * isCourseFaceRecognize 1 开启 0 关闭
         * 暂停播放
         * 第一次观看没有进度
         */
        //  console.log(this.TrainBasicInfo, 'this.TrainBasicInfo====')
        // console.log(this.faceRecognizeFail,'this.faceRecognizeFail====')
        // console.log(this.resourcesInfo,'this.resourcesInfo=====')

        if (
          !this.verification &&
          +this.TrainBasicInfo.isCourseFaceRecognize === 1 &&
          (!this.resourcesInfo.studyInfo ||
            (this.resourcesInfo.studyInfo.learnRate || 0) < 100)
        ) {
          if (this.faceRecognizeFail) {
            // 人脸识别失败或者过程中关闭了人脸识别弹窗点击开始播放再次弹起
            this.faceTcRecognizeDialog();
          }
          // else if (this.TrainBasicInfo.courseFaceRecognizeTime) {
          //   // 设置了人脸识别间隔时间
          //   this.setTcFaceRecognizeDialog()
          // }
        }
        /**
         * 设置按次数弹屏
         * 如果弹屏开始并且是按次数
         * 首次进入弹屏
         */
        if (
          this.TrainBasicInfo.isAlertWindow &&
          this.TrainBasicInfo.alertType &&
          (+this.$route.query.studyProcess || 0) < 100
        ) {
          this.setTcDialogNum();
        }
        // console.log(this.videoInfo.video.currentTime, this.videoInfo.deadline, '进度条变化this.videoInfo.video.currentTime')
        // 视频进度发生变化
        // this.videoInfo.video.showHideControls()
        this.videoInfo.fastForward = true;
        if (
          !this.videoInfo.video.seeking &&
          this.videoInfo.video.currentTime > this.videoInfo.deadline
        ) {
          this.videoInfo.deadline = this.videoInfo.video.currentTime + 3;
          let learnRate = Math.ceil(
            ((this.videoInfo.deadline || 0) / (this.tcplayer.duration() || 0)) *
            100
          );
          learnRate = learnRate > 100 ? 100 : learnRate;
          this.resourcesInfo.studyInfo.learnRate =
            learnRate >= this.resourcesInfo.studyInfo?.learnRate
              ? learnRate || 0
              : this.resourcesInfo.studyInfo?.learnRate || 0;
          this.resourcesInfo.studyInfo.playLength =
            this.videoInfo.deadline >= this.resourcesInfo.studyInfo?.playLength
              ? this.videoInfo.deadline - 3
              : this.resourcesInfo.studyInfo?.playLength;
        }
        // if (
        //   this.verification &&
        //   +this.TrainBasicInfo.isCourseFaceRecognize === 1
        //   && (!this.resourcesInfo.studyInfo || (this.resourcesInfo.studyInfo.learnRate || 0) < 100)
        // ) {

        //   this.videoInfo.video.pause();
        // }
      });

      // this.videoInfo.video.addEventListener('seeking', () => {
      this.tcplayer.on("seeking", async (e) => {
        // 视频拖拽以后把弹窗的临时时间重制
        if (
          e.target.player._TimeUpdateStamp &&
          this.videoInfo.video.currentTime - e.target.player._TimeUpdateStamp >
          1
        ) {
          this.timeTStr = 0;
          console.log(this.pauseTime, "this.pauseTime=======");
          // if(this.pauseTime != this.videoInfo.video.currentTime && (this.videoInfo.video.currentTime > this.videoInfo.deadline)){
          // this.videoInfo.video.currentTime = this.pauseTime;
          // }
          console.log(this.timeTStr, "seeking--timeTStr");
          console.log(
            this.videoInfo.deadline,
            "seeking--this.videoInfo.deadline"
          );
          console.log(
            this.videoInfo.video.currentTime,
            "seeking---this.videoInfo.video.currentTime"
          );
          console.log(
            this.tcplayer.currentTime(),
            "seeking---this.tcplayer.currentTime()"
          );

          if (
            this.allPlayEndIdList.indexOf(this.resourcesInfo.contentId) != -1
          ) {
            return;
          }

          if (
            this.resourcesInfo.studyInfo &&
            this.resourcesInfo.studyInfo.learnRate != 100
          ) {
            if (
              this.tcplayer.currentTime() > (this.videoInfo.deadline || 0) &&
              +this.TrainBasicInfo.isDraw == 1
            ) {
              console.log(1111111111111111111);
              this.videoInfo.video.currentTime = this.videoInfo.deadline - 3.3;
              console.log(
                this.videoInfo.video.currentTime,
                "seeking--this.videoInfo.video.currentTime"
              );
              if (
                this.videoInfo.fastForward &&
                this.resourcesInfo.studyInfo &&
                this.resourcesInfo.studyInfo.learnRate != 100
              ) {
                this.tcplayer.pause();
                this.dialogTableVisible = true;
              }
            }
          }
        }
      });
    },
    /**
     * 视频 音频播放器
     */
    async getaliPlay() {
      // 人脸识别需要的id
      this.businessId = this.resourcesInfo.contentId;
      var $ = require("jquery");
      $("#player-con").height("calc(100vh - 90px)");
      $("#player-con").children().remove();
      if ($("#J_prismPlayer").length > 0) {
        this.player && this.player.dispose();
        $("#J_prismPlayer").remove();
      }
      if (+this.resourcesInfo.contentType === 2) {
        const img =
          '<img class="music" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/music.png" />';
        $("#player-con").append(img);
      }
      const boarddiv = '<div id="J_prismPlayer"></div>';
      $("#player-con").append(boarddiv);
      $("#J_prismPlayer").height("100%");
      let format = "";
      let mediaType = "";

      if (this.resourcesInfo.contentType == 2) {
        format = "mp3";
        mediaType = "audio";
      }
      this.videoInfo.deadline = 0;
      let plugin = null;
      let m3u8Plugin = null;
      console.log(this.videoInfo, "信息");
      if (
        this.videoInfo.source?.sourceSing &&
        (this.videoInfo.source?.sourceSing == "222" ||
          this.videoInfo.source?.sourceSing == "333")
      ) {
        plugin = {
          id: "J_prismPlayer",
          source: this.videoInfo?.source.playUrl,
          width: "100%",
          height: "500px",
          autoplay: true,
          isLive: false,
          rePlay: false,
          playsinline: true,
          preload: false,
          controlBarVisibility: "hover",
          useH5Prism: true,
        };
      } else {
        m3u8Plugin = {
          id: "J_prismPlayer",
          vid: this.videoInfo.source.videoMeta.videoId,
          playauth: this.videoInfo.source.playAuth,
          cover: this.videoInfo.source.videoMeta.coverURL,
          width: "100%",
          height: "500px",
          autoplay: true,
          preload: false,
          isLive: false,
          useH5Prism: true,
          language: "zh-cn",
        };
        plugin = {
          id: "J_prismPlayer",
          vid: this.videoInfo.source.videoMeta.videoId,
          playauth: this.videoInfo.source.playAuth,
          cover: this.videoInfo.source.videoMeta.coverURL,
          width: "100%",
          height: "500px",
          autoplay: true,
          preload: false,
          isLive: false,
          useH5Prism: true,
          format: format || "m3u8",
          mediaType: mediaType || "video",
          language: "zh-cn",
        };
      }
      /* eslint-disable */
      this.player = new Aliplayer(
        this.courseName.indexOf("m3u8") !== -1 ? m3u8Plugin : plugin
      );
      /** 隐藏倍速  指对视频生效 */
      if (
        this.TrainBasicInfo.isDoubleSpeed &&
        document.querySelector("video")
      ) {
        document.querySelector(".prism-setting-speed").scsse = "display:none";
      }

      this.player.on("ready", () => {
        // console.log('videoready=========================')
        /**
         * isAlertWindow: 弹屏开启 1 开启 0 关闭
         * alertCount：弹屏次数
         * alertType：弹屏类型 1 按次数 0 按时间
         */
        if (
          this.TrainBasicInfo.isAlertWindow &&
          this.TrainBasicInfo.alertType
        ) {
          this.getShowAlertArrNum(
            this.player.getDuration(),
            this.TrainBasicInfo.alertCount
          );
        }

        /**
         * 记录上次播放进度
         */
        if (
          this.resourcesInfo.studyInfo &&
          this.resourcesInfo.studyInfo.playLength + 5 <=
          (this.videoInfo.source.videoMeta?.duration ||
            this.player.getDuration())
        ) {
          this.player.seek(this.resourcesInfo.studyInfo.playLength);
        } else {
          this.player.seek(1);
        }
      });

      /* eslint-disable */
      this.player.on("play", async () => {
        console.log(
          this.videoInfo.video.currentTime,
          this.player.getCurrentTime(),
          "开始播放当前时间this.videoInfo.video.currentTime====="
        );

        /** 清除人脸识别 */
        if (this.faceRecognizeTimer) {
          clearInterval(this.faceRecognizeTimer);
        }

        if (this.timer) {
          clearInterval(this.timer);
        }
        /**
         * 弹屏按时间
         * isAlertWindow: 弹屏开启 1 开启 0 关闭
         * alertType: 弹屏类型 2 按次数   1 按时间
         * alertTime：弹屏时间
         */
        if (
          this.TrainBasicInfo.isAlertWindow &&
          !this.TrainBasicInfo.alertType
        ) {
          console.log("弹屏1111");
          this.timer = setInterval(() => {
            console.log("弹屏222");
            this.player.pause();
            this.continueDialogVisible = true;
            clearInterval(this.timer);
          }, this.alertTime);
        }
        /**
         * 人脸验证，规则开启人脸识别并且设置 分钟
         * isCourseFaceRecognize 1 开启 0 关闭
         * 暂停播放
         * 第一次观看没有进度
         */
        console.log(this.TrainBasicInfo, "this.TrainBasicInfo====");
        if (
          +this.TrainBasicInfo.isCourseFaceRecognize === 1 &&
          (!this.resourcesInfo.studyInfo ||
            (this.resourcesInfo.studyInfo.learnRate || 0) < 100)
        ) {
          console.log("进入人脸识别");
          if (this.faceRecognizeFail) {
            // 人脸识别失败或者过程中关闭了人脸识别弹窗点击开始播放再次弹起
            console.log(
              "人脸识别失败或者过程中关闭了人脸识别弹窗点击开始播放再次弹起"
            );
            this.faceRecognizeDialog();
          } else if (this.TrainBasicInfo.courseFaceRecognizeTime) {
            // 设置了人脸识别间隔时间
            console.log("定时器人脸识别1111");
            this.setFaceRecognizeDialog();
          }
        }

        this.videoInfo.fastForward = false;
        if (
          this.resourcesInfo.studyInfo &&
          this.resourcesInfo.studyInfo.learnRate < 100 &&
          +this.TrainBasicInfo.isDraw === 1
        ) {
          /**
           * 培训设置视频防拖拽 调用此方法
           */
          this.progressSetting(this.resourcesInfo);
        }
        await this.courseClearTimeing();
        await this.courseTiming();
      });
      this.player.on("pause", async () => {
        console.log(
          this.videoInfo.video.currentTime,
          "暂停播放当前时间this.videoInfo.video.currentTime====="
        );
        this.pauseTime = this.videoInfo.video.currentTime;
        this.is = false;
        await this.courseClearTimeing();
      });
      /**
       * 视频播放完成
       */
      this.player.on("ended", async () => {
        /**
         * 视频播放完成 清楚弹窗定时器
         */
        this.continueDialogVisible = false; // 多加一层保障
        clearInterval(this.timer); // 清除弹屏定时器
        clearInterval(this.faceRecognizeTimer); // 清除人脸识别定时器
        await this.postCourseLearningLogSaveLog();
        this.allPlayEndIdList.push(this.resourcesInfo.contentId);
        this.is = true;
        this.courseClearTimeing();
        this.videoInfo.deadline = 0;
        /**
         * 完成 自动播放下一个
         */
        this.getNextCourseInfo();
      });

      this.videoInfo.video = document.querySelector("video");
      if (!this.videoInfo.video) {
        this.videoInfo.video = document.querySelector("audio");
      }
      if (!this.videoInfo.video) {
        return;
      }
      /**
       * 视频进度发生变化
       */
      this.videoInfo.video.addEventListener("timeupdate", () => {
        /**
         * 设置按次数弹屏
         * 如果弹屏开始并且是按次数
         * 首次进入弹屏
         */
        if (
          this.TrainBasicInfo.isAlertWindow &&
          this.TrainBasicInfo.alertType &&
          (+this.$route.query.studyProcess || 0) < 100
        ) {
          this.setDialogNum();
        }
        // console.log(this.videoInfo.video.currentTime, this.videoInfo.deadline, '进度条变化this.videoInfo.video.currentTime')
        // 视频进度发生变化
        // this.videoInfo.video.showHideControls()
        this.videoInfo.fastForward = true;
        if (
          !this.videoInfo.video.seeking &&
          this.videoInfo.video.currentTime > this.videoInfo.deadline
        ) {
          this.videoInfo.deadline = this.videoInfo.video.currentTime + 3;

          let learnRate = Math.ceil(
            ((this.videoInfo.deadline || 0) /
              (this.player.getDuration() || 0)) *
            100
          );
          learnRate = learnRate > 100 ? 100 : learnRate;
          this.resourcesInfo.studyInfo.learnRate =
            learnRate >= this.resourcesInfo.studyInfo.learnRate
              ? learnRate || 0
              : this.resourcesInfo.studyInfo.learnRate || 0;
          this.resourcesInfo.studyInfo.playLength =
            this.videoInfo.deadline >= this.resourcesInfo.studyInfo.playLength
              ? this.videoInfo.deadline - 3
              : this.resourcesInfo.studyInfo.playLength;
        }

        // console.log(this.videoInfo.video.currentTime, this.videoInfo.deadline, '进度条变化----this.videoInfo.video.currentTime')
      });

      this.videoInfo.video.addEventListener("seeking", (e) => {
        // 视频拖拽以后把弹窗的临时时间重制
        if (
          e.target.player._TimeUpdateStamp &&
          this.videoInfo.video.currentTime - e.target.player._TimeUpdateStamp >
          1
        ) {
          this.timeTStr = 0;
          console.log(this.pauseTime, "this.pauseTime=======");
          // if(this.pauseTime != this.videoInfo.video.currentTime && (this.videoInfo.video.currentTime > this.videoInfo.deadline)){
          // this.videoInfo.video.currentTime = this.pauseTime;
          // }
          console.log(this.timeTStr, "seeking--timeTStr");
          console.log(
            this.videoInfo.deadline,
            "seeking--this.videoInfo.deadline"
          );
          console.log(
            this.videoInfo.video.currentTime,
            "seeking---this.videoInfo.video.currentTime"
          );
          console.log(
            this.player.getCurrentTime(),
            "seeking---this.player.getCurrentTime()"
          );

          if (
            this.allPlayEndIdList.indexOf(this.resourcesInfo.contentId) != -1
          ) {
            return;
          }

          if (
            this.resourcesInfo.studyInfo &&
            this.resourcesInfo.studyInfo.learnRate != 100
          ) {
            if (
              this.player.getCurrentTime() > (this.videoInfo.deadline || 0) &&
              +this.TrainBasicInfo.isDraw == 1
            ) {
              this.videoInfo.video.currentTime = this.videoInfo.deadline - 3.3;
              console.log(
                this.videoInfo.video.currentTime,
                "seeking--this.videoInfo.video.currentTime"
              );
              if (
                this.videoInfo.fastForward &&
                this.resourcesInfo.studyInfo &&
                this.resourcesInfo.studyInfo.learnRate != 100
              ) {
                this.player.pause();
                this.dialogTableVisible = true;
              }
            }
          }
        }
      });
    },
    timeToSec(time) {
      var hour = time.split(":")[0];
      var min = time.split(":")[1];
      var sec = time.split(":")[2];
      var s = Number(hour * 3600) + Number(min * 60) + Number(sec);
      return s * 1000;
    },
    // 点击视频知识点跳转到对应标记处
    toMark(item) {
      const time = this.timeToSec(item.start);
      this.tcplayer && this.tcplayer.currentTime(time / 1000);
    },
    // 展示字幕
    showMark() {
      this.markDrawer = !this.markDrawer;
    },
    pdfTiming() {
      if (this.pdfIntervalName) {
        clearInterval(this.pdfIntervalName);
      }
      const saveLogTime = +this.saveLogTime * 1000;
      this.pdfIntervalName = setInterval(() => {
        this.saveLearningLog();
      }, saveLogTime);
    },
    clearPdfTimeing() {
      if (this.pdfIntervalName) {
        clearInterval(this.pdfIntervalName);
        this.pdfIntervalName = null;
        // console.log("清楚定时器");
      }
    },
    // 文档保存进度
    async saveLearningLog(type) {
      const data = {
        taskId: this.taskId || "",
        resId: this.resourcesInfo.contentId, // 资源id
        courseId: this.resourcesInfo.courseId || "", //课程下资源需要传
        cur: localStorage.getItem("page") || 1,
        max: localStorage.getItem("totalpage") || 1,
        pollingTime: type ? 1 : this.saveLogTime || 15,
        platform: 0,
      };
      await this.$api.coursePlay.postCourseLearningLogSaveLog({ data });
      return true;
    },

  },
  beforeDestroy() { },
  destroyed() {
    this.courseClearTimeing();
    this.clearPdfTimeing();
    this.player && this.player.pause();
    this.tcplayer && this.tcplayer.pause();
    if ($("#Vidpreview")) {
      $("#Vidpreview").remove();
    }
  },
};
</script>
<style lang="scss" src="../assets/css/index.scss" scoped></style>
<style lang="scss">
#player-con .music {
  width: 100%;
  position: absolute;
  top: 20%;
  left: 0;
  z-index: 99;
}

.video-js .vjs-control-bar {
  background: rgba(0, 0, 0, 0.75);
}

.video-js .vjs-progress-holder {
  height: 4px;
  background: #6d7e97;
  border-radius: 2px;
}

.video-js .vjs-play-progress {
  background: #1972ff;

  :before {
    width: 14px;
    height: 20px;
    border-radius: 4px;
    background: #fff;
    content: "";
  }
}

.tcp-dot-basic-style {
  background: #fff;
  width: 4px;
  height: 4px;
  display: block;
}

// 第一期不显示标记文本
.vjs-time-tooltip.tcp-popup-container.tcp-without-gif {
  background: transparent;
  display: none !important;
}

.vjs-text-track-display div div div {
  border-radius: 4px;
  font-size: 16px;
  padding: 7px 12px;
}

#J_prismPlayer {
  height: 100% !important;
}

.player-container-id-dimensions {
  width: 100%;
}

.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  border: 0;
  background-color: transparent;
}

.video-js .vjs-progress-control {
  top: 0;
  overflow: visible;
}
</style>
