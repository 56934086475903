<template>
    <div class="docPreview">
        <div
            id="Vidpreview"
            ref="Vidpreview"
            style="width: 100%;height: calc(100vh - 90px)"
        ></div>
    </div>
</template>

<script>
export default {
  name:'docPreview',
  props:{
    detailId:{type:Number},
    duration:{type:Number}
  },
  data(){
      return {
        fileUrl: '',
        demo: null,
      }
  },
  mounted(){
    console.log(this.detailId,'detailId====')
    this.getDocumentAccessToken()
  },
  methods:{
      // 获取文档
       getDocumentAccessToken () {
        console.log(1111111)
        const params = {
          resId: this.detailId
        }
        console.log(params,'====')
        return this.$api.resource.getDocumentAccessToken({ params }).then(async res => {
          if (res.data) {
            await this.getSourseDetail();
            await this.getTotal(res.data);
          }
        })
      },
      async getSourseDetail () {
        const params = {
          id: this.detailId
        }
        await this.$api.resource.getSourseDetail({ params }).then(res => {
          if (res.data) {
            this.fileUrl = res.data.fileUrl
          }
        })
      },
       async getTotal (data) {
          localStorage.removeItem('page')
          localStorage.removeItem('totalpage')
          // 下边是安全才有的
          // eslint-disable-next-line no-undef
          this.demo = aliyun.config({
            mount: document.querySelector('#Vidpreview'),
            url: data.previewURL //设置文档预览URL地址。
          })
          // //设置AccessToken。
          this.demo.setToken({ token: data.accessToken })
          await this.demo.ready()
          // console.log(this.fileUrl.includes('.ppt'))
          //文档类型为文字类型。
          // const wordApp = demo.WordApplication()
          // //文档类型为表格类型。
          // const excelApp = demo.ExcelApplication()
          //文档类型为演示类型。
          // const pptApp = demo.PPTApplication()
          //文档类型为PDF类型。
          // const pdfApp = demo.PDFApplication()
          //自动识别文档类型。
          this.app = this.demo.Application
          const { Enum } = this.app
          if (this.fileUrl.includes('.ppt') || this.fileUrl.includes('.pptx')) {
            let totalPagesz = await this.demo.PPTApplication().ActivePresentation
              .Slides.Count
            localStorage.setItem('page', totalPagesz)
            localStorage.setItem('totalpage', totalPagesz)
            /***
             *
             * 当前页数必须是切换生效，滚动页面不生效
             */
            // let totalPages = await demo.PPTApplication().ActivePresentation.SlideShowWindow.View.Slide.SlideIndex
            /*
            * 跳转指定页
            * @param: number
            */
            // await demo.PPTApplication().ActivePresentation.SlideShowWindow.View.GotoSlide(3)
          } else if (this.fileUrl.includes('.pdf')) {
            let totalPages = await this.demo.PDFApplication().ActivePDF.PagesCount
            localStorage.setItem('page', totalPages)
            localStorage.setItem('totalpage', totalPages)
            /*
            * 获取当前呀
            * @return: number
            */
            // let totalPages = await demo.PDFApplication().ActivePDF.CurrentPage
            /*
            *  跳转到相应页
            * @param : { PageNum: number }
            */
            //  let PageNum = 10
            // await demo.PDFApplication().ActivePDF.JumpToPage({PageNum})
          } else if (
            this.fileUrl.includes('.doc') ||
            this.fileUrl.includes('.docx')
          ) {
            let totalPages = await this.app.ActiveDocument.Range.Information(
              Enum.WdInformation.wdNumberOfPagesInDocument
            )

            if (totalPages.End) {
              console.log('加载完了！一共', totalPages, '页')
              localStorage.setItem('page', totalPages.PagesCount)
              localStorage.setItem('totalpage', totalPages.PagesCount)
            } else {
              localStorage.setItem(
                'page',
                this.duration == totalPages.PagesCount
                  ? totalPages.PagesCount
                  : this.duration
              )
              localStorage.setItem(
                'totalpage',
                this.duration == totalPages.PagesCount
                  ? totalPages.PagesCount
                  : this.duration
              )
            }
            /*
            * 获取当前呀
            * @param: WdInformation: {
            *      wdActiveEndPageNumber: 3
            *  }
            * @return: number
            */
            // let currentPage = await app.ActiveDocument.Selection.Information(Enum.WdInformation.wdActiveEndPageNumber)
            /*
            * 跳转指定
            * @param: { What?: WdGoToItem, Which?: WdGoToDirection.wdGoToAbsolute, Count?: number, Name?: string}
            * WdGoToItem: {
            *      wdGoToPage: 1,
            *  }
            *  WdGoToDirection: {
            *      wdGoToAbsolute: 1
            *  }
            */
            // await app.ActiveDocument.Selection.GoTo(Enum.WdGoToItem.wdGoToPage, Enum.WdGoToDirection.wdGoToAbsolute, 10)
            // //或者使用如下代码。
            // await app.ActiveDocument.Selection.GoTo({
            //     What: Enum.WdGoToItem.wdGoToPage,
            //     Which: Enum.WdGoToDirection.wdGoToAbsolute,
            //     Count: 10
            // })
          } else if (
            this.fileUrl.includes('.xls') ||
            this.fileUrl.includes('.xlsx')
          ) {
            const Names = []
            //For(start, end, step, handle)
            await this.app.For(1, this.app.Sheets.Count, 1, async Index => {
              Names.push(await this.app.Sheets.Item(Index).Name)
            })
            localStorage.setItem(
              'page',
              Names.length == this.duration ? Names.length : this.duration
            )
            localStorage.setItem(
              'totalpage',
              Names.length == this.duration ? Names.length : this.duration
            )
            // 获取当前sheet名称
            // await demo.ready()
            // const app = demo.ExcelApplication()
            // const name = await app.ActiveSheet.Name
            // console.log('ActiveSheet:', name)
            // 切换到指定sheet
            // await demo.ready()
            // const app = demo.ExcelApplication()
            // const sheetIndex = 1 //sheets序号，从1开始。
            // app.Sheets.Item(sheetIndex).Activate() //切换sheet。
          }
          // console.log(app,'app----')
        },
  }
}
</script>

<style lang="scss" scoped>
.docPreview{
  overflow: hidden  ;
}
</style>