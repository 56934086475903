<template>

    <el-dialog title="练习" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
        <div class="practice">
            <div class="practice-con">
                <div class="testPaper">
                    <!-- <div class="item" :id="'s' + item.questionId" v-for="(item, index) in questionsList"
                            :key="index"> -->
                    <div class="stem">
                        <h4>{{ currentQuestionIndex + 1 }}/{{questionDataDialog.length}}.【
                            {{
                                currentQuestion.type == 1
                                    ? '单选题'
                                    : currentQuestion.type == 2
                                        ? '多选题'
                                        : currentQuestion.type == 3
                                            ? '判断题'
                                            : currentQuestion.type == 4
                                                ? '高阶题'
                                                : currentQuestion.type == 6
                                                    ? '填空题'
                                                    : ''
                            }} / {{ currentQuestion.questionScore }}分



                            】<span v-html="currentQuestion.titleWithoutTag">{{currentQuestion.titleWithoutTag}}</span>
                        </h4>
                        <li v-for="(item1, index1) in currentQuestion.optionList" :key="index1">{{
                            item1.optionNumber }}<span
                                v-html="item1.optionContentWithoutTag">{{item1.optionContentWithoutTag}}</span></li>
                    </div>
                    <div class="option">
                        <div v-if="currentQuestion.type !== 6">
                            <li class="answer" v-for="(item1, index1) in currentQuestion.optionList" :key="index1"
                                @click="
                                    handleClickOption(
                                        currentQuestion,
                                        currentQuestion.optionList,
                                        currentQuestion.type,
                                        item1,
                                        index,
                                        index1
                                    )
                                    ">
                                <span v-if="currentQuestion.type != 2" class="option-btn" :class="{
                                    'active': item1.isAnswers
                                }" />
                                <span v-if="currentQuestion.type == 2" class="el-checkbox__input" :class="{
                                    'is-checked': item1.isAnswers
                                }">
                                    <span class="el-checkbox__inner"></span>
                                </span>
                                <span class="option-text">{{ item1.optionNumber }}</span>

                            </li>
                            <div class="answerView" v-if="currentQuestion.isShowAnswer">
                                <div class="rightAnswer">
                                    正确答案： {{ currentQuestion.rightAnswer }}
                                </div>
                                答案解析： {{ currentQuestion.remarkWithoutTag }}
                            </div>
                        </div>
                        <!-- <div v-if="item.type === 6" class="completionBox">
                                    <div class="Completion-item"
                                        v-for="(completion, completionIndex) in item.completionAnswer"
                                        :key="'comple' + completionIndex">
                                        <span class="label">第{{ completionIndex + 1 }}空：</span>
                                        <el-input class="CompletionAnswers" v-model="completion.answer"
                                            placeholder="请输入答案" @change="(val) => getop3(val, item)"></el-input>
                                    </div>
                                </div> -->

                    </div>
                    <!-- </div> -->
                </div>


            </div>
            <div class="footer">
                <el-button v-if="currentQuestion.type==2" type="primary" @click="submitOneQuestion">多选提交</el-button>
                <el-button v-if="questionsList.length - 1 !== currentQuestionIndex" type="primary"
                    @click="nextQuestion">下一题</el-button>
                <el-button v-if="questionsList.length - 1 == currentQuestionIndex" type="primary"
                    @click="submitExam">提交</el-button>
            </div>

        </div>
    </el-dialog>

</template>
<script>
export default {
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        resourceId: {
            type: Number,
            default: null
        },
        taskId: {
            type: String,
            default: ''
        },
        questionDataDialog: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        dialogVisible: {
            handler(val) {
                this.dialogShowV = val;
                this.getPriacticeList();
            },
            immediate: true,
        },
    },
    data() {
        return {
            dialogVisibleShow: this.dialogVisible,
            questionsList: [],
            winH: window.screen.height,
            saveData: {
                answerList: [], // 本试卷答题信息列表
            },
            currentQuestionIndex: 0, // 当前题目 0开始
            currentQuestion: {}, // 当前题目
            isShowAnswer: false,

        }
    },
    created() {
        console.log('dialogVisible', this.dialogVisible);
        console.log('resourceId', this.resourceId);
        // this.getPriacticeList();
    },
    methods: {
        submitOneQuestion() {
            console.log('submitOneQuestion');
            // 提交多选题目
            this.questionsList[this.currentQuestionIndex].isShowAnswer = true;
            this.$set(this.questionsList[this.currentQuestionIndex], "isShowAnswer", true);
            this.$forceUpdate();
        },
        // 点击下一题
        nextQuestion() {
            // 如果是单选题没有选择答案，不让下一题
            if (this.currentQuestion.type !== 2 && !this.currentQuestion.myAnswers) {
                this.$message({
                    message: '请选择答案',
                    type: 'warning'
                });
                return;
            }
            // 如果是多选题没有点击多选提交，不让下一题
            if (this.currentQuestion.type === 2 && !this.currentQuestion.isShowAnswer) {
                this.$message({
                    message: '请点击多选提交',
                    type: 'warning'
                });
                return;
            }
            if (this.currentQuestionIndex < this.questionsList.length - 1) {
                this.currentQuestionIndex++;
                this.currentQuestion = this.questionsList[this.currentQuestionIndex];
            }
        },
        // 点击题号锚点
        anchor(anchorName) {
            const anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                anchorElement.scrollIntoView();
            }
        },
        // 填空题
        getop3(val, item) {
            console.log('val, question------>', val, item)
            let num = 0;
            item.completionAnswer.forEach(item => {
                if (item.answer) {
                    num++;
                }
            })
            console.log(num, 'num===')
            if (num) {
                item.result = true;
            } else {
                item.result = false;
            }
        },
        // 选择          题目        选项集合    类型   选中项  题目   选项
        handleClickOption(question, optionList, type, item, index, index2) {
            const item1 = item;
            const myanswer = []; // 多选题答案
            /* type  1单选  2多选  3判断   4高阶*/
            if (type !== 2) {
                this.questionsList[this.currentQuestionIndex].isShowAnswer = true;
                if (item.isDisabled) {
                    return;
                }
                optionList.forEach((item2, index3) => {
                    const item4 = item2;
                    if (index2 === index3) {
                        this.$set(item1, "isAnswers", true);
                        this.$set(question, "myAnswers", item1.optionNumber);
                    } else {
                        this.$set(item4, "isAnswers", false);
                    }
                });
                question.result = true;
            } else {
                if (!item1.isAnswers) {
                    this.$set(item1, "isAnswers", true);
                } else {
                    this.$set(item1, "isAnswers", false);
                }
                optionList.forEach(item2 => {
                    if (item2.isAnswers) {
                        myanswer.push(item2.optionNumber);
                    }
                });
                this.$set(question, "myAnswers", myanswer.join(","));
                if (myanswer.length > 0) {
                    question.result = true;
                } else {
                    question.result = false;
                }
            }

        },
        submitExam() {
            console.log('submitExam');
            this.getPaperList();
            console.log('this.saveData.answerList', this.saveData.answerList);
            this.$api.resource.commitClassPractice({
                answerList: this.saveData.answerList,
                channel: 1,
                taskId: this.taskId
            }).then(res => {
                if (res.success) {
                    this.$message({
                        message: '提交成功',
                        type: 'success'
                    });
                    this.dialogVisible = false;
                    this.$emit('submitExam');
                }
            });

        },
        getPaperList() {
            this.saveData.answerList = [];
            this.questionsList.forEach(item => {
                const item1 = item;
                console.log(item1, 'item1===')
                // if (item1.type === 6) {

                //     let num = 0;
                //     item1.completionAnswer.forEach(completionItem => {
                //         if (completionItem.answer !== "") {
                //             num++;
                //         }
                //         myAnswers.push(completionItem.answer)
                //     })
                //     if (num) {
                //         item1.myAnswers = myAnswers.join(',')
                //     }
                // }
                if (item1.myAnswers && item1.myAnswers.length > 1 && item1.type !== 6) {
                    let userAnswer = item1.myAnswers.split(",");
                    item1.myAnswers = Array.from(userAnswer)
                        .sort()
                        .join(",");
                }
                if (item1.myAnswers !== undefined) {
                    this.saveData.answerList.push({
                        userAnswer: item1.myAnswers,
                        questionId: item1.questionId,
                        // questionType: item1.type,
                    });
                }
            });
        },
        getPriacticeList() {
            this.questionsList = [];
            this.questionsList = this.questionDataDialog;
            console.log('this.questionDataDialog', this.questionDataDialog);
            this.questionsList = this.questionsList.filter(item => item.type !== 6);
            this.questionsList.forEach(item => {
                item.isShowAnswer = false;
                item.rightAnswer = '';
                item.optionList.forEach(item1 => {
                    if (item1.result == 1) {
                        item.rightAnswer += item1.optionNumber;
                    }
                })
            })

            this.currentQuestion = this.questionsList[0];

            // this.$api.resource.getRandomQuestion({
            //     resourceId: this.resourceId,
            //     // resourceId: 16260

            // }).then(res => {
            //     if (res.success) {
            //         if (res.data.length) {
            //             // 去除type是6的题目
            //             res.data = res.data.filter(item => item.type !== 6);
            //             this.questionsList = res.data;
            //             this.questionsList.forEach(item=>{
            //                 item.isShowAnswer = false;
            //                 item.rightAnswer = '';
            //                 item.optionList.forEach(item1=>{
            //                     if(item1.result==1){
            //                         item.rightAnswer += item1.optionNumber;
            //                      }
            //                  })
            //             })

            //             this.currentQuestion = this.questionsList[0];

            //         }

            //     }
            //     console.log('res', res);
            // });
            console.log('getPriacticeList');
        },
        handleClose(done) {
            done();
        }
    }
}
</script>
<style scoped lang="scss">
.rightAnswer {
    color: #1972FF;
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    line-height: 20px;
}

.answerView {
    padding: 10px;
    background: #f5f7fa;
    margin-top: 10px;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    line-height: 20px;
}

.footer {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.practice {
    width: 100% !important;
}

.practice-con {
    display: flex;
    justify-content: left;
}

.stem {
    padding-bottom: 18px;
    margin-bottom: 12px;
    border-bottom: 1px dashed #d7dcee;

    h4 {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333;
        margin-bottom: 16px;

        span {
            color: #666;
            word-break: break-all;

            ::v-deep img {
                display: inline-block;
                max-width: 100% !important;
                height: auto !important;
                vertical-align: top;
            }

            ::v-deep p {
                display: inline-block;
            }
        }
    }

    li {
        list-style: none;
        margin-left: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666;
        line-height: 24px;
        margin-bottom: 6px;

        span {
            margin-left: 6px;
            word-break: break-all;

            ::v-deep img {
                display: inline-block;
                max-width: 100% !important;
                height: auto !important;
                vertical-align: top;
            }

            ::v-deep p {
                display: inline-block;
            }
        }
    }
}



.option {
    padding-left: 24px;

    .answer {
        display: inline-block;
        margin-right: 56px;

        .option-btn {
            display: inline-block;
            width: 16px;
            height: 16px;
            background: #fff;
            border: 1px solid #dddfe6;
            border-radius: 50%;
            margin-right: 8px;
            margin-top: 5px;
            vertical-align: top;
            cursor: pointer;

            &.active {
                background: #fff;
                border: 1px solid #316fff;
                text-align: center;
                line-height: 16px;

                &::after {
                    content: "";
                    width: 10px;
                    height: 10px;
                    background: #316fff;
                    border-radius: 50%;
                    display: inline-block;
                }
            }
        }

        .el-checkbox__input {
            white-space: nowrap;
            cursor: pointer;
            outline: none;
            display: inline-block;
            position: relative;
            vertical-align: baseline;
            margin-right: 8px;

            &.is-checked {
                .el-checkbox__inner {
                    background-color: #1972ff;
                    border-color: #1972ff;
                }
            }

            .el-checkbox__inner {
                display: inline-block;
                position: relative;
                border: 1px solid #dcdfe6;
                border-radius: 2px;
                box-sizing: border-box;
                width: 16px;
                height: 16px;
                background-color: #fff;
                z-index: 1;
                transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46), background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);

                &::after {
                    box-sizing: content-box;
                    content: "";
                    border: 1px solid #fff;
                    border-left: 0;
                    border-top: 0;
                    height: 8px;
                    left: 5px;
                    position: absolute;
                    top: 1px;
                    transform: rotate(45deg) scaleY(0);
                    width: 3px;
                    transition: transform 0.15s ease-in 0.05s;
                    transform-origin: center;
                    transform: rotate(45deg) scaleY(1);
                }
            }
        }

        .option-text {
            font-size: 20px;
            color: #1b2257;
            font-weight: 400;
            line-height: 28px;
            cursor: pointer;
        }
    }
}
</style>
