var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":"练习","visible":_vm.dialogVisible,"width":"50%","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"practice"},[_c('div',{staticClass:"practice-con"},[_c('div',{staticClass:"testPaper"},[_c('div',{staticClass:"stem"},[_c('h4',[_vm._v(_vm._s(_vm.currentQuestionIndex + 1)+"/"+_vm._s(_vm.questionDataDialog.length)+".【 "+_vm._s(_vm.currentQuestion.type == 1 ? '单选题' : _vm.currentQuestion.type == 2 ? '多选题' : _vm.currentQuestion.type == 3 ? '判断题' : _vm.currentQuestion.type == 4 ? '高阶题' : _vm.currentQuestion.type == 6 ? '填空题' : '')+" / "+_vm._s(_vm.currentQuestion.questionScore)+"分 】"),_c('span',{domProps:{"innerHTML":_vm._s(_vm.currentQuestion.titleWithoutTag)}},[_vm._v(_vm._s(_vm.currentQuestion.titleWithoutTag))])]),_vm._l((_vm.currentQuestion.optionList),function(item1,index1){return _c('li',{key:index1},[_vm._v(_vm._s(item1.optionNumber)),_c('span',{domProps:{"innerHTML":_vm._s(item1.optionContentWithoutTag)}},[_vm._v(_vm._s(item1.optionContentWithoutTag))])])})],2),_c('div',{staticClass:"option"},[(_vm.currentQuestion.type !== 6)?_c('div',[_vm._l((_vm.currentQuestion.optionList),function(item1,index1){return _c('li',{key:index1,staticClass:"answer",on:{"click":function($event){return _vm.handleClickOption(
                                    _vm.currentQuestion,
                                    _vm.currentQuestion.optionList,
                                    _vm.currentQuestion.type,
                                    item1,
                                    _vm.index,
                                    index1
                                )}}},[(_vm.currentQuestion.type != 2)?_c('span',{staticClass:"option-btn",class:{
                                'active': item1.isAnswers
                            }}):_vm._e(),(_vm.currentQuestion.type == 2)?_c('span',{staticClass:"el-checkbox__input",class:{
                                'is-checked': item1.isAnswers
                            }},[_c('span',{staticClass:"el-checkbox__inner"})]):_vm._e(),_c('span',{staticClass:"option-text"},[_vm._v(_vm._s(item1.optionNumber))])])}),(_vm.currentQuestion.isShowAnswer)?_c('div',{staticClass:"answerView"},[_c('div',{staticClass:"rightAnswer"},[_vm._v(" 正确答案： "+_vm._s(_vm.currentQuestion.rightAnswer)+" ")]),_vm._v(" 答案解析： "+_vm._s(_vm.currentQuestion.remarkWithoutTag)+" ")]):_vm._e()],2):_vm._e()])])]),_c('div',{staticClass:"footer"},[(_vm.currentQuestion.type==2)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitOneQuestion}},[_vm._v("多选提交")]):_vm._e(),(_vm.questionsList.length - 1 !== _vm.currentQuestionIndex)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.nextQuestion}},[_vm._v("下一题")]):_vm._e(),(_vm.questionsList.length - 1 == _vm.currentQuestionIndex)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitExam}},[_vm._v("提交")]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }