// 节流函数: 连续不断地触发某事件（如点击），只在单位时间内只触发一次
export default function throttle(fun, delay=2000) {
    let last, deferTimer
    return function (args) {
        console.log(args);
        let that = this;
        let _args = arguments;

        let now = +new Date();
        
        if(last && now < last + delay) {
            clearTimeout(deferTimer);
            deferTimer = setTimeout(function () {
                last = now;
                fun.apply(that, _args);
            },delay)
        } else {
            last = now;
            fun.apply(that,_args);
        }
    }
}