import throttle from "@/utils/throttle";
export default {
    bind(el){
        el.onmousedown = function (e) {
            //获取鼠标点击处分别与div左边和div上边的距离：鼠标位置-div位置
            let startX = e.clientX - el.offsetLeft,
            startY = e.clientY - el.offsetTop;
            el.setAttribute('data-drag',false) //鼠标点击
            throttle(
                (document.onmousemove = function (ev) {
                        let event = ev || window.event;
                        let moveX = event.clientX - startX,
                        moveY = event.clientY - startY;
                        if(moveY>0){
                            el.style.left = moveX + "px";
                            el.style.top = moveY + "px";
                            el.setAttribute('data-drag',true) //鼠标是移动
                        }
                }),
              500
            );
    
            // 鼠标弹起时不再移动
            document.onmouseup = function () {
                el.style.pointerEvents = null
                document.onmousemove = null;
                document.onmouseup = null;
            }
            return false;
        };
    }
}